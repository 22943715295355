if(window.Vue === undefined)
{
    window.Vue = require('vue');
}
import Newsletter from './Components/Newsletter';

const app = new Vue({
    el: '#newsletter',
    components: {
        'newsletter': Newsletter,
    }
});
