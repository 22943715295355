<template>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">

                <div class="row align-items-md-center mb-4 mb-lg-0">
                    <div class="col-sm-2 col-3">

                        <img src="/images/site/icons/icon-mail.png" alt="">

                    </div><!-- col -->
                    <div class="col-sm-10 col-9 pl-0">

                        <h3>Newsletter</h3>

                        <p class="text-success mb-0" v-if="success">
                            {{ successMessage }}
                        </p>
                        <p v-else class="mb-0">Abonează-te pentru a primi ofertele noastre</p>
                        <p class="text-danger text-sm mb-0" v-if="errors.email" v-text="errors.email[0]"></p>

                    </div><!-- col -->
                </div><!-- row -->

            </div><!-- col -->
            <div class="col-lg-6">

                <form class="newsletter-form" action="#">

                    <div class="form-group">
                        <input type="email"
                               v-model="email"
                               name="email"
                               placeholder="Introdu adresa de e-mail">
                        <button class="btn btn-primary"
                                @click.prevent="saveEmail"
                                :disabled="isLoading"
                                type="submit">Abonare</button>
                    </div><!-- form-group -->

                </form>

            </div><!-- col -->
        </div><!-- row -->
    </div><!-- container -->
</template>

<script>
export default {
    data()
    {
        return {
            email: null,
            errors: [],
            isLoading: false,
            success: false,
            successMessage: null,
        }
    },

    methods:
    {
        saveEmail()
        {
            this.isLoading = true;
            this.errors = [];
            this.success = false;
            this.successMessage = null;

            axios.post('/newsletter', {email: this.email})
                .then(response => {
                    this.isLoading = false;
                    this.success = true;
                    this.successMessage = response.data;
                    this.email = undefined;
                })
                .catch(error => {
                    this.errors = error.response.data.errors;
                    this.isLoading = false;
                });
        }
    }
}
</script>
